@font-face {
    font-family: 'Century Gothic';
    src: url('./GOTHIC.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Century Gothic';
    src: url('./GOTHICB0.TTF') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Century Gothic';
    src: url('./GOTHICBI.TTF') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Century Gothic';
    src: url('./GOTHICI.TTF') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  