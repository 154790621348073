.App {
  text-align: center;
}

#drawer #wrapper{
  background-color: "whitesmoke";
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.nav_link{
  color: #1f3f66;
  background-color: none;
  padding: 8px 16px;
  text-decoration: none;
  font-weight: 700;
  display: "flex";
  align-items: "center";
  cursor: "pointer";
  margin-left: 1.1rem;
text-align: center;
}

.nav_link_active{
  color: white;
  background-color: #1f3f66;
  padding: 8px 16px;
  text-decoration: none;
  font-weight: 700;
  display: "flex";
  align-items: "center";
  margin-left: 1.1rem;
  border-radius: .75rem 0 .75rem 0;
  cursor: "pointer";
  text-align: center;
}
.nav_link:hover{
  background-color: #1f3f660a;
  border-radius: .75rem 0 .75rem 0;
  cursor: "pointer";

}

.nav_link_active:hover{
  background-color: #234772;
  cursor: "pointer";
}

/* invisible items (FaciligateCustomers) */
.invisible {
  opacity: 0;
  transform: translateX(-50px); /* Slide in from the left */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* visible items (FaciligateCustomers) */
.visible {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* infinite scroll items ((SupportedProductsAnimation)) */

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.product-wheel {
  flex-direction: row;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  height: 100px;
  --animationspeed: 45s;
  --animationdelay: calc( var(--animationspeed) / 2 );
  padding-top: 1em;
  padding-bottom: 1em;
}
.product-wheel::before {
  position: absolute;
  background-image: linear-gradient(to right,rgb(240 244 252) 0%,rgba(255,255,255,0) 100%);
  content: '';
  height: 100%;
  width: 5%;
  z-index: 2;
  pointer-events: none;
}
.product-wheel::after{
  position: absolute;
  background-image: linear-gradient(to left,rgba(240 244 252) 0%,rgba(255,255,255,0) 100%);
  content: '';
  height: 100%;
  width: 5%;
  z-index: 2;
  pointer-events: none;
  right: 0;
}

.product-slide{
    flex-shrink: 0;
    position: absolute;
    animation: slidelogo 35s linear infinite;
    display: flex;
justify-content: space-around;
width: calc(250px * 18);}

.product-item {
  display: flex;
  justify-content: center;
  align-self: center;
  animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
  width: 250px;
}

@keyframes slidelogo {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 9))}
}

html, body { 
  scroll-behavior: smooth
}

.test-item {
  border: 2px solid red;
}

.log-text{
  font-size: 12px;
}
